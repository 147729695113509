import React from 'react';
import { useState, useEffect } from 'react';
import { POSTCARDS } from "../data";
import Gallery from './Gallery';

export default function HomePage({}) {
    return (
      <div id="homepage-container">
        <div id="header">
          <div id="title">
            <h1>Welcome to my postcard collection</h1>
          </div>
        </div>
        <div><a href="/turtles"> Turtles</a></div>
        <div><a href="/loupaper"> LouPaper</a></div>
        <div><a href="/harrypotter"> Harry Potter</a></div>
        <div><a href="/pokemon"> Pokémon</a></div>
        <div><a href="/papersisters"> Papersisters</a></div>
      </div>
    );
  }
  